import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/common/Button';
import { Input } from 'src/components/common/Input';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { openDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys } from 'src/store/dialog/types';
import './styles.scss';

const ActivityLimitations: FC = () => {
  const { t } = useTranslation();
  const [userActivityTime, setUserActivityTime] = useState<string>(localStorage.userActivityTime || '');

  const _openDialog = useConnectedAction(openDialog);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    setLimit: React.Dispatch<React.SetStateAction<string>>
  ): void => {
    const { value } = e.target;
    // Allow only numeric values
    if (/^\d*\.?\d*$/.test(value)) {
      setLimit(value);
    }
  };

  const handleSet = (): void => {
    localStorage.setItem('userActivityTime', JSON.stringify(Number(userActivityTime)));
    _openDialog({
      dialogType: EnumDialogsKeys.LIMITS_POPUP,
      dialogProps: { data: t('userActivityLimit') },
    });
    console.log(userActivityTime);
  };
  return (
    <div>
      <p className={'limit_title'}>{t('activityLimits')}</p>
      <div className="limitRow">
        <p className="limitRow_tilte">{t('userActivityLimit')}</p>
        <Input
          className="limitRow_input"
          value={userActivityTime}
          type="text"
          name="userActivityLimit"
          onChange={(e) => handleInputChange(e, setUserActivityTime)}
        />
        <Button onClick={handleSet} className="limitRow_btn" disabled={!userActivityTime}>
          {userActivityTime ? t('edit') : t('set')}
        </Button>
      </div>
    </div>
  );
};

export default ActivityLimitations;
