import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/common/Button';
import { Input } from 'src/components/common/Input';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { getPlayerLimits, setPlayerLimit, updatePlayerLimit } from 'src/services/limitsApi';
import { openDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys } from 'src/store/dialog/types';
import './styles.scss';

const Limitations: FC = () => {
  const { t } = useTranslation();
  const [dailyLimit, setDailyLimit] = useState<string>('');
  const [weeklyLimit, setWeeklyLimit] = useState<string>('');
  const [monthlyLimit, setMonthlyLimit] = useState<string>('');
  const [dailySeted, setDailySeted] = useState<number>();
  const [weeklySeted, setWeeklySeted] = useState<number>();
  const [monthlySeted, setMonthlySeted] = useState<number>();

  const _openDialog = useConnectedAction(openDialog);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    setLimit: React.Dispatch<React.SetStateAction<string>>
  ): void => {
    const { value } = e.target;
    // Allow only numeric values
    if (/^\d*\.?\d*$/.test(value)) {
      setLimit(value);
    }
  };

  const isDisabled = (value: string, type: 'dailyLimit' | 'weeklyLimit' | 'monthlyLimit'): boolean => {
    // eslint-disable-next-line sonarjs/prefer-single-boolean-return
    if (Number(value)) {
      switch (type) {
        case 'dailyLimit':
          return Number(dailyLimit) === dailySeted;
        case 'weeklyLimit':
          return Number(weeklyLimit) === weeklySeted;
        case 'monthlyLimit':
          return Number(monthlyLimit) === monthlySeted;
        default:
          return false;
      }
    } else {
      return true;
    }
  };

  const handleSuccess = (data: any): void => {
    data.forEach((element: any) => {
      switch (element.period) {
        case 'day':
          setDailyLimit(element.amount);
          setDailySeted(element.amount);
          break;
        case 'week':
          setWeeklyLimit(element.amount);
          setWeeklySeted(element.amount);
          break;
        case 'month':
          setMonthlyLimit(element.amount);
          setMonthlySeted(element.amount);
          break;
        default:
          break;
      }
    });
  };

  const handleSet = async (type: 'day' | 'week' | 'month', isUpdate?: boolean): Promise<void> => {
    const limitsMap = {
      day: Number(dailyLimit),
      week: Number(weeklyLimit),
      month: Number(monthlyLimit),
    };
    if (isUpdate) {
      updatePlayerLimit({ type: 'deposit', amount: limitsMap[type], period: type }).then((res) => {
        if (res.success) {
          const element = res.result;
          switch (element.period) {
            case 'day':
              setDailyLimit(element.amount);
              setDailySeted(element.amount);
              break;
            case 'week':
              setWeeklyLimit(element.amount);
              setWeeklySeted(element.amount);
              break;
            case 'month':
              setMonthlyLimit(element.amount);
              setMonthlySeted(element.amount);
              break;
            default:
              break;
          }
        }
        _openDialog({
          dialogType: EnumDialogsKeys.LIMITS_POPUP,
          dialogProps: { data: t(type) },
        });
      });
    } else {
      setPlayerLimit({ type: 'deposit', amount: limitsMap[type], period: type }).then((res) => {
        if (res.success) {
          handleSuccess(res.result);
        }
        _openDialog({
          dialogType: EnumDialogsKeys.LIMITS_POPUP,
          dialogProps: { data: t(type) },
        });
      });
    }
  };

  const getDepositLimits = async (): Promise<void> => {
    const res = await getPlayerLimits('deposit');
    if (res.success) {
      handleSuccess(res.result);
    }
  };

  useEffect(() => {
    getDepositLimits();
  }, []);

  return (
    <div>
      <p className={'limit_title'}>{t('depositLimits')}</p>
      <div className="limitRow">
        <p className="limitRow_tilte">{t('daily')}</p>
        <Input
          className="limitRow_input"
          value={dailyLimit}
          type="text"
          name="dailyLimit"
          onChange={(e) => handleInputChange(e, setDailyLimit)}
        />
        <Button
          onClick={() => handleSet('day', Boolean(dailySeted))}
          className="limitRow_btn"
          disabled={isDisabled(dailyLimit, 'dailyLimit')}
        >
          {dailySeted ? t('edit') : t('set')}
        </Button>
      </div>
      <div className="limitRow">
        <p className="limitRow_tilte">{t('weekly')}</p>
        <Input
          className="limitRow_input"
          value={weeklyLimit}
          type="text"
          name="weeklyLimit"
          onChange={(e) => handleInputChange(e, setWeeklyLimit)}
        />
        <Button
          onClick={() => handleSet('week', Boolean(weeklySeted))}
          className="limitRow_btn"
          disabled={isDisabled(weeklyLimit, 'weeklyLimit')}
        >
          {weeklySeted ? t('edit') : t('set')}
        </Button>
      </div>
      <div className="limitRow">
        <p className="limitRow_tilte">{t('monthly')}</p>
        <Input
          className="limitRow_input"
          value={monthlyLimit}
          type="text"
          name="monthlyLimit"
          onChange={(e) => handleInputChange(e, setMonthlyLimit)}
        />
        <Button
          onClick={() => handleSet('month', Boolean(monthlySeted))}
          className="limitRow_btn"
          disabled={isDisabled(monthlyLimit, 'monthlyLimit')}
        >
          {monthlySeted ? t('edit') : t('set')}
        </Button>
      </div>
    </div>
  );
};

export default Limitations;
