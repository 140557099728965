import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Input } from 'src/components/common/Input';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useResetSignUpError } from 'src/hooks/useResetSignupErrors';
import { setError } from 'src/store/user/actions';
import { RootState } from 'src/types/store-types';
import * as Yup from 'yup';
import _styles from './styles.module.scss';

const PHONE_NUMBER_LENGTH_BY_COUNTRY_CODE: any = {
  '+595': 10,
};

type IProps = {
  name: string;
  value: string;
  regexp: string;
  onChange: (e: any) => void;
  placeholder?: string;
  required: boolean;
};
const Phone_Number_Input: FC<IProps> = (props) => {
  const { t }: Translation = useTranslation();

  const _setErrorReq = useConnectedAction(setError);
  const resetSignUpError = useResetSignUpError();

  const { errors: signUpErrors, phoneCode } = useSelector((state: RootState) => state.user);

  const [code, setCode] = useState<string>('');

  const phoneNumberRegExp = useMemo(() => {
    return props.regexp ? new RegExp(props.regexp) : new RegExp('^[0-9]*$');
  }, [props.regexp]);

  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .required(t('requiredField'))
      .matches(phoneNumberRegExp, t('phoneNumberError'))
      .test(
        'phoneNumberLengthCheck',
        t('phoneNumberLengthError', { length: PHONE_NUMBER_LENGTH_BY_COUNTRY_CODE[phoneCode] }),
        (val = '') => {
          if (PHONE_NUMBER_LENGTH_BY_COUNTRY_CODE[phoneCode] && process.env.REACT_APP_WEBSITENAME === 'Carnaval') {
            return val.length === PHONE_NUMBER_LENGTH_BY_COUNTRY_CODE[phoneCode];
          }
          return true;
        }
      ),
  });

  const handleValidate = async (value: string): Promise<void> => {
    try {
      await validationSchema.validate({ phoneNumber: value }, { abortEarly: false });
      resetSignUpError('phoneNumber');
    } catch (error: any) {
      if (error.name === 'ValidationError') {
        _setErrorReq({ ...signUpErrors, phoneNumber: error.errors[0] });
      }
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (signUpErrors.phoneNumber) {
      resetSignUpError('phoneNumber');
    }

    props.onChange(e);
  };

  const handleBlur = (): void => {
    handleValidate(props.value);
  };

  useEffect(() => {
    !!phoneCode && setCode(phoneCode);
    if (props.value) {
      handleValidate(props.value);
    }
  }, [phoneCode]);

  return (
    <div className={_styles.phone_number_input_wrapper}>
      <Input
        name="phone-code"
        label="phoneNumber_label"
        onChange={handleOnChange}
        value={code}
        type="text"
        disabled
        required={props.required}
        className={_styles.phoneCode}
      />
      <Input
        error={signUpErrors.phoneNumber}
        {...props}
        onChange={handleOnChange}
        value={props.value}
        type="number"
        disableNumberSymbols
        label="phoneNumber_label2"
        minValue={0}
        onBlur={handleBlur}
        className={_styles.phoneNumber}
      />
    </div>
  );
};
export default Phone_Number_Input;
