import React, { FC, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import GameBox from 'src/components/base-components/GameBox';
import GameBoxMessaging from 'src/components/base-components/GameBoxMessaging';
import AccountModal from 'src/components/base-components/Modals/AccountModal';
import Dialogs from 'src/components/base-components/Modals/Dialogs';
import EmailVerificationModal from 'src/components/base-components/Modals/EmailVerificationModal';
import ErrorMessage from 'src/components/base-components/Modals/ErrorMessage';
import ForceLogout from 'src/components/base-components/Modals/ForceLogoutModal';
import TrackGA from 'src/components/base-components/TrackGA/TrackGa';
// import InactivityModal from 'src/components/base-components/Modals/InactivityModal';
import Routing from 'src/components/Routes/Routing';
import {
  configsVariablesHandler,
  detectDevice,
  envBoolean,
  getFreeSpinMessages,
  getFromQueryParams,
  getSkinId,
  isTokenExpired,
  onInactive,
  setBodyOverflow,
  setParams,
} from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useI18n } from 'src/hooks/use-i18n-translation';
import useMessagesHandler from 'src/hooks/use-messages-handler';
// import useMessagesSocket from 'src/hooks/use-messages-socket';
import { usePersistPaletteLocalStorage } from 'src/hooks/use-persist-palette';
import { usePostMesssage } from 'src/hooks/use-postMessage';
import useGbMessages from 'src/hooks/useGBMessages';
import { useNavigateToCategory } from 'src/hooks/useNavigateToCategory';
import { useQuery } from 'src/hooks/useQuery';
import { getExternalAppUrl } from 'src/services/authorizationApi';
import { getPallete } from 'src/services/configsApi';
import { gameUrlRequest, getMessages } from 'src/services/gameApi';
import PersisterService from 'src/services/PersisterService';
import { verifyEmail } from 'src/services/userApi';
import { openMyAccountModal } from 'src/store/account-modal/actions';
import { setDeepLink, setFinalTourInfo, setSelectedCategory } from 'src/store/app/actions';
import { openAuthModal } from 'src/store/auth-modal/actions';
import { setCategoriesData, setPageConfigs } from 'src/store/configs/actions';
import { openDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys } from 'src/store/dialog/types';
import { setTlTWidgetConfig } from 'src/store/tlt-widget/actions';
import { setLogout, setPhoneCode, setReferralLink, signIn } from 'src/store/user/actions';
import { GameParamType } from 'src/types/req-dto/game';
import { RootState } from 'src/types/store-types';
import { DEFAULT_INACTIVITY_TIME, ONE_HOUR, PAPIGAMES_AFFILATES, THEMES } from 'src/utils/constants';
import GeneralLoaderPage from '../GeneralLoaderPage';
import LoginPage from '../LoginPage';
import './styles.scss';

export const Main: FC = () => {
  const navigate = useNavigate();
  const device = detectDevice();
  const dispatch = useDispatch();

  const initialTimeoutRef: any = useRef(null);
  const hourlyIntervalRef: any = useRef(null);

  const [hoursSpent, setHoursSpent] = useState(() => {
    // Retrieve hoursSpent from localStorage on initial load
    const savedHours = localStorage.getItem('hoursSpent');
    return savedHours ? parseInt(savedHours, 10) : 0;
  });

  usePersistPaletteLocalStorage();
  // useMessagesSocket();
  useMessagesHandler();
  useGbMessages();

  const { search, pathname } = useLocation();
  const queries = useQuery();
  const { locale } = useI18n();
  const skinId = getSkinId();

  const casinoTournamentIframe: any = document.querySelector('iframe#tournament');

  const personalInfo = useSelector((state: RootState) => state.user?.user?.personalInfo);
  const { referralLinks } = useSelector((state: RootState) => state.user);
  const { deepLink } = useSelector((state: RootState) => state.app);

  const params = getFromQueryParams(search);

  const _autoSignInReq = useConnectedAction(signIn.autoReq);
  const _setFinalTourInfo = useConnectedAction(setFinalTourInfo.req);
  const _logout = useConnectedAction(setLogout);
  const _setCategoriesData = useConnectedAction(setCategoriesData.req);
  const _openAccountModal = useConnectedAction(openMyAccountModal);
  const _setPageConfigs = useConnectedAction(setPageConfigs.req);
  const _setSelectedCategory = useConnectedAction(setSelectedCategory);
  const _setDeepLink = useConnectedAction(setDeepLink);
  const _openAuthModal = useConnectedAction(openAuthModal);
  const _setPhoneCode = useConnectedAction(setPhoneCode);
  const _openDialog = useConnectedAction(openDialog);
  const _setTlTWidgetConfig = useConnectedAction(setTlTWidgetConfig);
  const _setReferralLink = useConnectedAction(setReferralLink);

  const { generalLoading } = useSelector((state: RootState) => state.app);
  const { user, forceLogout } = useSelector((state: RootState) => state.user);
  const { generalConfigs } = useSelector((state: RootState) => state.configs);
  const { tltWidgetConfig } = useSelector((state: RootState) => state.tltWidgetConfig);
  const [inactivityModalVisibility, setInactivityModalVisibility] = useState<boolean>(false);
  const [verificationModalVisibility, setVerificationModalVisibility] = useState<boolean>(false);
  const [forceLogoutModalVisibility, setForceLogoutModalVisibility] = useState<boolean>(false);
  const [activityTrigger, setActivityTrigger] = useState(false);
  const [openTournamentByHostInitativeFlag, setOpenTournamentByHostInitativeFlag] = useState<boolean>(false);

  const navigateToCategory = useNavigateToCategory();

  const link = useMemo(() => {
    return personalInfo?.referralUrl || referralLinks?.referralUrl;
  }, [personalInfo, referralLinks]);

  const gameBox = useMemo(() => {
    if (user && process.env.REACT_APP_GAME_BOX) {
      return <GameBox />;
    }
    if (!user && params.gameBox_category) {
      _openAuthModal('login');
    }
    return null;
  }, [user, params.gameBox_category]);
  const gameBoxMessaging = useMemo(() => {
    if (user && process.env.REACT_APP_GAME_BOX_MESSAGING_MODULE) {
      return <GameBoxMessaging />;
    }
    return null;
  }, [user]);

  const getGameUrl = async (gameId: number): Promise<any> => {
    const params: GameParamType = setParams(user, { id: gameId }, skinId, locale, isMobile);
    try {
      return await gameUrlRequest(params);
    } catch (e: any) {
      console.log('e = ', e);
    }
  };

  const loginPage = useMemo(() => {
    if (envBoolean(process.env.REACT_APP_HAS_LOGIN_PAGE as string) && !user) {
      return <LoginPage />;
    }
    return <></>;
  }, [user]);

  const handleStorageMessages = (event: any): void => {
    if (event.key === 'sessionId' && event.oldValue && !event.newValue) {
      _logout();
    }
  };

  // const toggleInactivityVisibility = (): void => {
  //   setInactivityModalVisibility(!inactivityModalVisibility);
  // };

  const toggleVerficationVisibility = (): void => {
    setVerificationModalVisibility(!verificationModalVisibility);
  };

  const toggleForceLogoutModalVisibility = (): void => {
    setForceLogoutModalVisibility(!forceLogoutModalVisibility);
  };
  // comment for club app autologin
  // const handleInactivityLogout = (): void => {
  //   _logout({
  //     onSuccessCb: () => {
  //       window.location.href = '/';
  //       toggleInactivityVisibility();
  //       _setPageConfigs();
  //     },
  //   });
  // };

  const clearBannersFromStorage = (): void => {
    sessionStorage.removeItem('homeGrid');
    sessionStorage.removeItem('banners');
  };

  const navigateToLogin = async (): Promise<void> => {
    if (user) {
      getExternalAppUrl({ token: user.token, authClientAppId: queries.authClientAppId }).then((res: any) => {
        if (res?.result?.authClientUrl) {
          _openDialog({
            dialogType: EnumDialogsKeys.DINHU_ANALIZER,
            dialogProps: { data: res?.result?.authClientUrl },
          });
        }
      });
    } else {
      _openAuthModal('login');
    }
  };

  const navigateToDeepLinkReg = (): void => {
    if (process.env.REACT_APP_WEBSITENAME === 'PapiGames' && params.gbBonusCode) {
      sessionStorage.setItem('gbBonusCode', params.gbBonusCode);
      if (user && isTokenExpired()) {
        navigateToCategory();
      } else {
        _openAuthModal('registration');
        _setDeepLink('registrationModal');
      }
    }
    if (params.affiliateId) {
      sessionStorage.setItem('affiliateParams', JSON.stringify({ id: params.affiliateId }));
      if (user && isTokenExpired()) {
        navigateToCategory();
      } else {
        _openAuthModal('registration');
        _setDeepLink('registrationModal');
      }
    }
    if (process.env.REACT_APP_WEBSITENAME === 'PapiGames' && params.trackerID) {
      sessionStorage.setItem('trackerID', params.trackerID);
    }
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const navigateToDeepLink = (): void => {
    switch (params.navigateTo) {
      case 'provider':
        if (params.providername) {
          _setSelectedCategory('casino');
          navigate(`/category/casino/${search}`);
        }
        break;
      case 'privacy':
        setTimeout(() => {
          _openAccountModal({ section: 'info_and_rules', subSection: 'Privacy Policy', fullHeight: true });
        }, 1000);

        break;
      case 'register':
        if (!user) {
          _openAuthModal('registration');
        }
        break;
      case 'login':
        if (!user) {
          _openAuthModal('login');
        }
        break;
      case 'GoS':
        if (user) {
          if (!pathname.includes('casinoTournament')) {
            navigate(`/category/casinoTournament/${search}`);
            _setSelectedCategory('casinoTournament');
          }
          setOpenTournamentByHostInitativeFlag(true);
        } else {
          _openAuthModal('login');
        }

        break;
      case 'liveChat':
        _setDeepLink('liveChat');
        navigateToCategory();
        break;
      case 'deposit':
        // if (params.amount && typeof Number(params.amount) === 'number' && !isNaN(Number(params.amount))) {
        //   sessionStorage.setItem('deepDepositValue', params.amount);
        // }
        if (user) {
          _setDeepLink('deposit');
          navigateToCategory();
        } else {
          _openAuthModal('login');
          _setDeepLink('deposit');
        }
        break;
      case 'raf':
        if (user) {
          _setReferralLink({ referralUrl: link, referralId: personalInfo?.referralId || 0 });
          _openAccountModal({ section: 'referAFriend', subSection: 'referAFriend' });
        } else {
          _openAuthModal('login');
          _setDeepLink('raf');
        }
        break;
      default:
        navigateToCategory();
        break;
    }
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const setPalette = (): void => {
    let _res = null;
    let _theme = '';

    if (localStorage.getItem('selectedPalette')) {
      _theme = localStorage.getItem('selectedPalette') as string;
    }

    if (PersisterService.hasPersistValue('palette')) {
      _res = PersisterService.getPersistValue('palette');
      // TO DO change palete name
      const t = _res ? process.env.REACT_APP_DEFAULT_PALLETE : THEMES[0];
      const palette = Boolean(_theme) ? _res[_theme] : t;
      configsVariablesHandler(palette);
      localStorage.setItem('selectedPalette', Boolean(_theme) ? _theme : Object.keys(_res)[0]);
    } else {
      getPallete().then((res: any) => {
        if (res.result) {
          _res = res.result;
          const palette = Boolean(_theme) ? _res[_theme] : _res[process.env.REACT_APP_DEFAULT_PALLETE as string];
          PersisterService.setPersistValue('palette', res.result);
          configsVariablesHandler(palette);
          localStorage.setItem(
            'selectedPalette',
            Boolean(_theme) ? _theme : (process.env.REACT_APP_DEFAULT_PALLETE as string)
          );
        }
      });
    }
  };

  useEffect(() => {
    const { websiteToken, uiTheme, skinId, logo } = queries;
    const theme = uiTheme ? uiTheme.replace(/["']/g, '') : 'dark';
    if (websiteToken && skinId) {
      _autoSignInReq({
        data: { token: websiteToken, skinId: +skinId },
        onSuccessCb: () => {
          console.log('auto sign in success');
        },
        dispatch,
      });
      sessionStorage.setItem('dynamicClubAppSkinId', skinId);
    }
    if (logo) {
      sessionStorage.setItem('dynamicClubAppLogo', logo);
    }
    if (uiTheme) {
      localStorage.setItem('selectedPalette', theme);
      setPalette();
    }
  }, [queries]);

  useEffect(() => {
    if (process.env.REACT_APP_TLT_WIDGET_ENABLE && !tltWidgetConfig.key) {
      getGameUrl(16).then((res) => {
        if (res?.success) {
          _setTlTWidgetConfig(res?.result?.data);
        }
        return null;
      });
    }
  }, []);

  useEffect(() => {
    if (casinoTournamentIframe && openTournamentByHostInitativeFlag) {
      setTimeout(() => {
        casinoTournamentIframe?.contentWindow.postMessage(
          { type: 'openDialogByHostInitiative', dialogType: 'tournamentRegister', tournamentId: params.tourId },
          '*'
        );
        setOpenTournamentByHostInitativeFlag(false);
      }, 1000);
    }
    window.addEventListener('message', (e) => {
      if (e.data?.name === 'clickAction') {
        setActivityTrigger((prevState) => !prevState);
      }
    });
  }, [casinoTournamentIframe, openTournamentByHostInitativeFlag]);

  useEffect(() => {
    if (user && !inactivityModalVisibility && generalConfigs) {
      onInactive(
        () => setInactivityModalVisibility(true),
        () => setInactivityModalVisibility(false),
        +generalConfigs?.inactivitiTime || DEFAULT_INACTIVITY_TIME
      );
    }
  }, [user, inactivityModalVisibility, generalConfigs, activityTrigger]);

  useEffect(() => {
    if (!process.env.REACT_APP_MESSAGING_LIB_ENABLED && process.env.REACT_APP_MESSAGES_URL) {
      if (user) {
        getMessages(user.id).then((res: any) => {
          const messages = getFreeSpinMessages(res?.result?.messages[user.id]);

          if (!!messages?.length) {
            _openDialog({
              dialogType: EnumDialogsKeys.FREE_SPINS,
              dialogProps: { data: messages[0] },
            });
          }
        });

        (window as any)._smartico_user_id = user.id;
        (window as any)._smartico_language = localStorage.selectedLanguage;
      } else {
        (window as any)._smartico_user_id = null;
        (window as any)._smartico_language = null;
      }
    }
  }, [user]);

  useEffect(() => {
    if (!envBoolean(process.env.REACT_APP_HAS_GENERAL_LOADER as string)) {
      _setPageConfigs();
      _setCategoriesData();
    }

    setPalette();

    window.addEventListener('storage', handleStorageMessages);
    return () => window.removeEventListener('storage', handleStorageMessages);
  }, [locale]);

  useEffect(() => {
    if (!search.includes('navigateTo') && process.env.REACT_APP_FILE_STORAGE_ADDRESS) {
      _setFinalTourInfo(locale);
    }
  }, [locale]);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const verificationKey = queryParams.get('verificationKey');
    if (verificationKey) {
      verifyEmail({ key: verificationKey });
      _openDialog({ dialogType: EnumDialogsKeys.SECCESSFULLY_REGED, dialogProps: { data: null } });
    }

    navigateToDeepLink();
    if (search.includes('authClientAppId')) {
      navigateToLogin();
    }
    if (!search.includes('provider')) {
      navigateToDeepLinkReg();
    }
    window.onbeforeunload = clearBannersFromStorage;
  }, []);

  useEffect(() => {
    if (!!generalConfigs) {
      document?.body.setAttribute('class', `${device} ${generalConfigs?.template}`);
      _setPhoneCode(generalConfigs?.defaultPhoneCode);
    }
  }, [generalConfigs]);

  useEffect(() => {
    if (!!forceLogout?.message) {
      setForceLogoutModalVisibility(true);
    }
  }, [!!forceLogout?.message]);

  useEffect(() => {
    if (isMobile && deepLink === 'raf' && user) {
      setTimeout(() => {
        _openAccountModal({ section: 'referAFriend', subSection: 'referAFriend' });
        _setDeepLink('');
      }, 0);
    }
  }, [deepLink, user]);

  useEffect(() => {
    if (process.env.REACT_APP_ENABLE_NOTIFICATIONS) {
      // Check if the browser supports notifications
      if ('Notification' in window && typeof Notification.requestPermission === 'function') {
        // Request permission from the user
        Notification.requestPermission().then(function (permission) {
          if (permission === 'denied') {
            console.log('Permission for notifications was denied.');
          }
        });
      } else {
        console.log('This browser does not support desktop notifications.');
      }
    }
  }, []);

  useLayoutEffect(() => {
    setTimeout(() => {
      if (
        process.env.REACT_APP_LIBRARY_MODULE_LIST?.length &&
        !(window as any)?._gamebox_skin_id &&
        (window as any)?.__gdlib
      ) {
        (window as any)?.__gdlib?.init({
          moduleList: process.env.REACT_APP_LIBRARY_MODULE_LIST,
          websiteName: process.env.REACT_APP_WEBSITENAME,
          skinId: skinId,
          language: locale || localStorage.getItem('selectedLanguage'),
          environment: process.env.REACT_APP_MODE,
          logo: `${window.location.origin}/images/logo.svg`,
          theme: 'dark',
          showThemeSwitcher: false,
        });
        (window as any)?.registerCB('openLoginForm', () => {
          _openAuthModal('login');
          isMobileOnly && setBodyOverflow('unset');
        });
        (window as any)?.registerCB('openDepositModal', () => {
          _openAccountModal({ section: 'deposit', subSection: 'deposit' });
          isMobileOnly && setBodyOverflow('unset');
        });
      }
    }, 0);
  }, []);

  // FOR PAPIGAMES streamers affilliateId
  useEffect(() => {
    const affilate = PAPIGAMES_AFFILATES.find((affiliate) => pathname.includes(affiliate.name));
    if (affilate) {
      const id: string = affilate.id;

      sessionStorage.setItem('affiliateParams', JSON.stringify({ id }));
      if (user && isTokenExpired()) {
        navigateToCategory();
      } else {
        if (!pathname.includes('provider')) {
          _openAuthModal('registration');
          _setDeepLink('registrationModal');
          setBodyOverflow('unset');
        }
      }
    }
  }, [pathname, user]);

  useEffect(() => {
    if (user && (window as any)?.__gdlib) {
      (window as any)?.__gdlib?.login(user?.token);
    }
  }, [user]);

  useEffect(() => {
    if ((window as any)?.__gdlib) {
      (window as any)?.__gdlib?.changeLanguage(locale);
    }
  }, [locale]);

  // Set up a 3-hour initial delay, then hourly alerts if the user is signed in
  useEffect((): any => {
    if (process.env.REACT_APP_ACTIVITY_TRACKER === 'true') {
      if (user) {
        if (hoursSpent >= 3) {
          // If already past the 3-hour mark, immediately set up the hourly interval
          hourlyIntervalRef.current = setInterval(() => {
            setHoursSpent((prevHours) => {
              const newHours = prevHours + 1;
              _openDialog({
                dialogType: EnumDialogsKeys.ACTIVITY_POPUP,
                dialogProps: { data: newHours },
              });
              localStorage.setItem('hoursSpent', JSON.stringify(newHours));
              return newHours;
            });
          }, ONE_HOUR); // 1 hour
        } else {
          // Initial 3-hour delay for first-time users
          initialTimeoutRef.current = setTimeout(() => {
            _openDialog({
              dialogType: EnumDialogsKeys.ACTIVITY_POPUP,
              dialogProps: { data: 3 },
            });
            setHoursSpent(3);
            localStorage.setItem('hoursSpent', JSON.stringify(3));

            // After first alert, start hourly intervals
            hourlyIntervalRef.current = setInterval(() => {
              setHoursSpent((prevHours) => {
                const newHours = prevHours + 1;
                _openDialog({
                  dialogType: EnumDialogsKeys.ACTIVITY_POPUP,
                  dialogProps: { data: newHours },
                });
                localStorage.setItem('hoursSpent', JSON.stringify(newHours));
                return newHours;
              });
            }, ONE_HOUR); // 1 hour
          }, (3 - hoursSpent) * ONE_HOUR); // Remaining time until 3-hour mark
        }

        // Cleanup on component unmount or user change
        return () => {
          clearTimeout(initialTimeoutRef.current);
          clearInterval(hourlyIntervalRef.current);
        };
      } else {
        // Clear storage and intervals on logout
        clearTimeout(initialTimeoutRef.current);
        clearInterval(hourlyIntervalRef.current);
        localStorage.removeItem('hoursSpent');
        setHoursSpent(0);
      }
    }
  }, [user, _openDialog, setHoursSpent]);

  usePostMesssage((v: any) => configsVariablesHandler(v), 'pallete');

  return (
    <>
      {loginPage}
      {envBoolean(process.env.REACT_APP_HAS_GENERAL_LOADER as string) && !generalLoading.includes(false) && (
        <GeneralLoaderPage />
      )}
      {process.env?.REACT_APP_GOOGLE_ANALYTICS && <TrackGA />}

      <AccountModal />
      {gameBox}
      {gameBoxMessaging}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* {user && inactivityModalVisibility && (
        <InactivityModal
          doAction={handleInactivityLogout}
          doActivity={toggleInactivityVisibility}
          open={inactivityModalVisibility}
        />
      )} */}
      <Dialogs />
      <ErrorMessage />
      <EmailVerificationModal open={verificationModalVisibility} onCancel={toggleVerficationVisibility} />
      <ForceLogout open={forceLogoutModalVisibility} onClose={toggleForceLogoutModalVisibility} />
      <Routing />
    </>
  );
};
