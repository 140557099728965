import { FC, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ArrayTransformationHelper } from 'src/helpers/ArrayTransformationHelper';
import { envBoolean } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { CMSManager } from 'src/manager/CMSManager';
import { getCustomI18n } from 'src/services/dataApi';
import { setCustomI18N } from 'src/store/app/actions';
import { setGeneralConfigs } from 'src/store/configs/actions';
import { RootState } from 'src/types/store-types';
import { EnumLangCodes } from 'src/utils/constants';
import { I18nContext } from './../components/render-props/I18Props';

const FALLBACK_LANGAUAGE =
  localStorage.getItem('selectedLanguage') ?? (process.env.REACT_APP_FALLBACK_LANGUAGE as string);

type Props = {
  children: ReactNode;
};
export const I18nProvider: FC<Props> = ({ children }) => {
  const { i18n }: Translation = useTranslation();

  const _setCustomI18N = useConnectedAction(setCustomI18N);
  const _getGeneralConfigs = useConnectedAction(setGeneralConfigs.req);

  const { user } = useSelector((state: RootState) => state.user);
  const { generalConfigs } = useSelector((state: RootState) => state.configs);

  const [locale, setLocale] = useState<string>(FALLBACK_LANGAUAGE);
  const [locales, setLocales] = useState<SelectOptionType[]>([]);

  const setTranslations = (result: any): void => {
    if (locale !== generalConfigs?.fallbackLng) {
      const customTranslationsInFallBackLang = result?.filter(
        (f: any) => EnumLangCodes[f.attributes.locale] === generalConfigs?.fallbackLng
      );
      if (customTranslationsInFallBackLang.length > 0) {
        _setCustomI18N(customTranslationsInFallBackLang?.[0]?.attributes);
      }
    }
  };

  const getCustomI18nTranslation = (): void => {
    !!process.env.REACT_APP_FILE_STORAGE_ADDRESS &&
      getCustomI18n().then((res: any) => {
        if (!!res?.data) {
          const result = Array.isArray(res?.data) ? res?.data : [res?.data];

          const customTranslations = CMSManager[process.env.REACT_APP_FILE_STORAGE_TYPE as string].getCustomI18N(
            result,
            EnumLangCodes[locale]
          );

          if (customTranslations) {
            _setCustomI18N(customTranslations);
          } else {
            setTranslations(result);
          }
        }
      });
  };

  useEffect(() => {
    if (!!generalConfigs?.languages) {
      // remove stored language if it not exist in localStorage and set fallback lang
      const lang = localStorage.getItem('selectedLanguage');

      if ((lang && !generalConfigs?.languages.includes(lang)) || !lang) {
        localStorage.setItem('selectedLanguage', process.env.REACT_APP_FALLBACK_LANGUAGE as string);
      }

      setLocales(
        ArrayTransformationHelper.convertIntoSelectOptionModel(generalConfigs.languages, ['name', 'name', 'icon'])
      );
    }
  }, [generalConfigs]);

  useEffect(() => {
    getCustomI18nTranslation();
  }, [user, locale]);

  useEffect(() => {
    if (!envBoolean(process.env.REACT_APP_HAS_GENERAL_LOADER as string)) {
      _getGeneralConfigs();
    }

    if (localStorage.getItem('selectedLanguage')) {
      const lang = localStorage.getItem('selectedLanguage');
      i18n.changeLanguage(lang);
    } else {
      i18n.changeLanguage(process.env.REACT_APP_FALLBACK_LANGUAGE as string);
      localStorage.setItem('selectedLanguage', process.env.REACT_APP_FALLBACK_LANGUAGE as string);
    }
  }, []);

  return (
    <I18nContext.Provider
      value={{
        locale,
        setLocale,
        setLocales,
        locales,
      }}
    >
      {children}
    </I18nContext.Provider>
  );
};
