import cn from 'classnames';
import { ChangeEvent, FC, useMemo, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Input } from 'src/components/common/Input';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { icons } from 'src/configs/icons';
import * as Yup from 'yup';
import _styles from './styles.module.scss';

type Props = {
  title: string;
  value: string | number | null;
  isEditable?: boolean;
  updateField?: any;
  fieldName: string;
  emailVerifed?: boolean;
};

const AccountDetail: FC<Props> = ({ title, value, isEditable, updateField, fieldName, emailVerifed }) => {
  const { t }: any = useTranslation();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [fieldValue, setFieldValue] = useState<string | number | null>(value);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const validationSchemas = {
    email: Yup.string().trim().required(t('requiredField')).email(t('pleaseEnterValidEmailAdress')),
    phoneNumber: Yup.string()
      .required(t('requiredField'))
      .matches(/^[0-9+]*$/, 'phoneNumberError'),
    address: Yup.string().matches(/^.*$/, t('fieldLength')),
    city: Yup.string().matches(/^.{2,24}$/, t('fieldLength', { count: '2-24' })),
  };

  const handleValidate = async (fieldValue: string | number | null): Promise<void> => {
    const schema = validationSchemas[fieldName as keyof typeof validationSchemas];
    if (!schema) return;

    try {
      await schema.validate(fieldValue);
      setErrorMessage('');
    } catch (error: any) {
      setErrorMessage(error.message || t('invalidValue'));
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const newValue = e.target.value;
    setFieldValue(newValue);
    handleValidate(newValue);
  };

  const handleBlur = (e: ChangeEvent<HTMLInputElement>): void => {
    setFieldValue(e.target.value);
    handleValidate(e.target.value);
  };

  const handleClickOnEdit = (): void => {
    setIsEditMode(true);
  };

  const handleCancel = (): void => {
    setIsEditMode(false);
    setFieldValue(value);
    setErrorMessage('');
  };

  const onUpdateField = (fieldName: string, fieldValue: any): void => {
    if (!errorMessage) {
      updateField(fieldName, fieldValue);
    }
  };

  const verifedIcon = useMemo(() => {
    if (emailVerifed) {
      return <SvgIcon src={icons.verifed} className={_styles.verifed} />;
    }
    return <SvgIcon src={icons.unverifed} className={_styles.verifed} />;
  }, [emailVerifed, title]);

  const stylesObj = useMemo(() => {
    if (isMobileOnly && process.env.REACT_APP_WEBSITENAME === 'Betelite') {
      return {
        backgroundColor: '#1f212c',
      };
    } else {
      return {};
    }
  }, []);
  return (
    <div className={_styles.detail_container} style={stylesObj}>
      <span className={_styles.title}>
        {t(title)} {title.includes('email') && verifedIcon}
      </span>
      {isEditable ? (
        <div className={_styles.value_container}>
          {isEditMode ? (
            <Input
              name={title}
              value={fieldValue ?? ''}
              size="md"
              onChange={handleChange}
              type={fieldName === 'phoneNumber' ? 'number' : 'text'}
              onBlur={handleBlur}
              error={errorMessage}
              className={_styles.edit_input}
              errorClassName={_styles.edit_input_error}
            />
          ) : (
            <span className={_styles.value}>{value || '-'}</span>
          )}
          {isEditMode ? (
            <div className={_styles.edit_icons_wrapper}>
              <SvgIcon
                className={cn(_styles.icon, _styles.success)}
                src={icons.success}
                onClick={() => onUpdateField(fieldName, fieldValue)}
              />
              <SvgIcon className={cn(_styles.icon, _styles.cancel)} src={icons.remove} onClick={handleCancel} />
            </div>
          ) : (
            <SvgIcon className={_styles.icon} src={icons.edit} onClick={handleClickOnEdit} />
          )}
        </div>
      ) : (
        <div className={_styles.detail_container} style={stylesObj}>
          <span className={_styles.value}>{value || '-'}</span>
        </div>
      )}
    </div>
  );
};
export default AccountDetail;
