import cn from 'classnames';
import React, { forwardRef, useCallback, useMemo } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { Typography } from 'src/components/common/Typography';
import { icons } from 'src/configs/icons';
import { isIOS } from 'src/helpers/utils';
import PersisterService from 'src/services/PersisterService';
import { SIGN_UP_PLACEHOLDERS } from 'src/utils/constants';
import { Components } from '../../configs/components/components';
import _styles from './styles.module.scss';

type Props = {
  settings: {
    registrationSteps: any[];
    styles?: { [key: string]: any };
    affiliateId?: string | number | null;
  };
  children: any;
  step?: number;
  ref: any;
};

type Ref = HTMLDivElement;
// eslint-disable-next-line sonarjs/cognitive-complexity
const SignUpContructor = forwardRef<Ref, Props>(({ settings, step, children }, ref) => {
  const { affiliateId = '', styles = {} } = settings;

  const { t }: Translation = useTranslation();

  const showStep = useMemo(() => Boolean(settings?.registrationSteps.length), [settings, step]);

  const steps = useMemo(() => {
    return settings?.registrationSteps.map((_, i) => i + 1);
  }, [settings]);

  const getProps = (props: any, key: string): any => {
    return Object.keys(props).reduce((acc: any, cur: string): any => {
      if (props[cur]) {
        if (SIGN_UP_PLACEHOLDERS.includes(cur)) {
          acc[cur] = `${key}_${cur}`;
        } else if (key === 'parentId' && affiliateId) {
          acc.value = affiliateId;
          acc.disabled = true;
        } else {
          acc[cur] = props[cur];
        }

        acc.name = key;
      }
      return acc;
    }, {});
  };

  const renderWidgets = useCallback(
    (settings: { [key: string]: any }): JSX.Element => {
      if (!Object.keys(settings).length) {
        return <></>;
      }

      const orderIndex: any = {};
      Object.keys(settings).forEach((key: string) => (orderIndex[`${key}`] = settings[key].priority));

      const widgetLine: any = Object.keys(settings)
        .sort((a: string, b: string) => orderIndex[a] - orderIndex[b])
        .filter((key: string) => key !== 'styles')
        .map((key: string, index: number) => {
          return React.createElement(Components[key], {
            ...getProps(settings[key], key),
            key: key + index,
            tabIndex: index + 1,
          });
        });

      return widgetLine.map((widget: any, i: number) => {
        return (
          <div key={i} style={styles} className={_styles.input_container}>
            {widget}
          </div>
        );
      });
    },
    [settings, step]
  );

  const setActiveStep = useCallback(
    (s: number) => {
      if (step) {
        if (step > s && s !== steps.length) {
          return <SvgIcon src={icons.done} />;
        } else {
          return s;
        }
      }
      return null;
    },
    [step]
  );

  const formMaxHeightForMobile = useMemo(() => {
    // TO DO make auth calculate or use flex
    let withoutImage = false;
    if (PersisterService.hasPersistValue('authConfigs')) {
      withoutImage = PersisterService.getPersistValue('authConfigs')?.[2]?.type === 'withoutImage';
    }

    if (isIOS()) return `calc(100svh - ${withoutImage ? '200px' : '340px'})`;

    return `calc(100vh - ${withoutImage ? '270px' : '400px'})`;
  }, []);

  const formMaxHeightForDesktop = useMemo(() => {
    return steps.length > 1 ? '350px' : '410px';
  }, []);

  return (
    <div className="signUp-section" style={styles}>
      {showStep && step ? (
        <div className={_styles.step_outer_container}>
          <div className={cn(_styles.step_container, 'steps')}>
            <div className={_styles.rules}>
              <p>{t('rule_part_1')}</p>
              <span>*</span>
              <p>{t('rule_part_2')}</p>
            </div>
            <div className={_styles.tab_container}>
              {steps.length > 1 &&
                steps?.map((s, index) => (
                  <div
                    key={s}
                    className={cn(_styles.tab, {
                      [_styles.selected]: s === step,
                      [_styles.done]: step > s && s !== steps.length,
                      [_styles[`steps_divider${index}`]]: steps.length > 2,
                    })}
                  >
                    <div className={_styles.step}>{setActiveStep(s)}</div>
                    <Typography variant="h6" className={_styles.title}>
                      {t('step')}
                    </Typography>
                  </div>
                ))}
            </div>
          </div>
          <div
            className={cn(_styles.form, 'signup_form')}
            ref={ref}
            style={{
              maxHeight: isMobileOnly ? formMaxHeightForMobile : formMaxHeightForDesktop,
            }}
          >
            {step && renderWidgets(settings?.registrationSteps[step - 1])}
            {children}
          </div>
        </div>
      ) : (
        <div
          style={{
            maxHeight: isMobileOnly ? formMaxHeightForMobile : formMaxHeightForDesktop,
          }}
        >
          {renderWidgets(settings.registrationSteps[0])}
        </div>
      )}
    </div>
  );
});
export default SignUpContructor;
