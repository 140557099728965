import { FC, useEffect, useMemo } from 'react';
import { isDesktop, isMobileOnly } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { detectDevice } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { gameListRequest } from 'src/services/gameApi';
import { setGamesTotalCount, setSelectedTag, setTags } from 'src/store/games/actions';
import { RootState } from 'src/types/store-types';
import {
  EGT_GAME_MAP,
  GAME_VISIABLE_COUNT_FOR_DESKTOP,
  GAME_VISIABLE_COUNT_FOR_MOBILE,
  SPECIAL_TAGS,
} from 'src/utils/constants';
import GameCardPage from './GameCardsPage';
import HomePage from './HomePage';
import './styles.scss';

type Props = {
  settings: {
    priority: number;
    styles: Styles;
  };
};
const GameTags: FC<Props> = () => {
  const navigate = useNavigate();
  const device = detectDevice();

  const _setSelectedTag = useConnectedAction(setSelectedTag);
  const _setGamesTotalCount = useConnectedAction(setGamesTotalCount);
  const _setTags = useConnectedAction(setTags);

  const { selectedCategory, tagsData } = useSelector((state: RootState) => state.app);
  const { user } = useSelector((state: RootState) => state.user);
  const { tags, selectedTag, selectedProvider, gamesTotalCount } = useSelector((state: RootState) => state.games);
  const { generalConfigs, navigation } = useSelector((state: RootState) => state.configs);

  const gamesCount =
    device === 'mobile' ? generalConfigs?.gamesCountPerPageMobile : generalConfigs?.gamesCountPerPageDesktop;

  const homeTag: any = useMemo(() => {
    return navigation.filter((f) => f.name === selectedCategory)[0];
  }, [selectedCategory, navigation]);

  const _device = useMemo(() => {
    return device === 'tablet' ? 'mobile' : device;
  }, []);

  const changeVisibleGamesCount = (showMore: boolean): void => {
    const offset = tags?.length && showMore ? Math.floor(tags.length / gamesCount) * gamesCount : 0;

    gameListRequest(_device, selectedProvider, offset, selectedCategory, [selectedTag], gamesCount).then((res: any) => {
      const _tags = tags ? tags : [];
      const newGames = res.games.map((game: any) => {
        if (game.providerName === 'EGT Digital') {
          return {
            ...game,
            gameKey: EGT_GAME_MAP[game.launchId],
          };
        } else {
          return game;
        }
      });

      _setTags(showMore ? [..._tags, ...newGames] : newGames);
      _setGamesTotalCount(res.totalCount);
    });
  };

  const gameCards = useMemo(() => {
    if (selectedTag === 'home' && !!tagsData && !!homeTag?.hasHomeTag) {
      return <HomePage gameTags={tagsData} homeTag={homeTag} />;
    }

    return (
      <GameCardPage
        tagGames={tags}
        gamesTotalCount={gamesTotalCount}
        changeVisibleGamesCount={changeVisibleGamesCount}
        gamesMinVisibleCount={isDesktop ? GAME_VISIABLE_COUNT_FOR_DESKTOP : GAME_VISIABLE_COUNT_FOR_MOBILE}
      />
    );
  }, [selectedTag, tagsData, homeTag, tags, selectedCategory, user]);

  const getSpecialTags = (): void => {
    const _user = localStorage.getItem('user');

    if (selectedTag === 'last5Games' && _user && ['casino', 'liveCasino'].includes(selectedCategory)) {
      const _selectedTag = tagsData?.allTagData?.tags?.filter(
        (m) => m.tagName === selectedTag || m.tagTrName === selectedTag
      )?.[0];

      if (_selectedTag) {
        _setTags(_selectedTag.tagGames);
        _setGamesTotalCount(_selectedTag.tagTotal);
      }
    } else {
      navigate(`/category/${selectedCategory}`);
    }
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    if (tagsData && SPECIAL_TAGS.includes(selectedTag)) {
      getSpecialTags();
    } else {
      if (selectedTag !== 'home' && selectedProvider && tagsData?.allTagData?.tags?.length) {
        const _selectedTag = tagsData?.allTagData?.tags?.find(
          (m) => m.tagName === selectedTag || m.tagTrName === selectedTag
        );
        if (['all_providers', ''].includes(selectedProvider?.[0]) && !isMobileOnly) {
          if (_selectedTag) {
            _setTags(_selectedTag.tagGames);
            _setGamesTotalCount(_selectedTag.tagTotal);
          }
        } else {
          if (_selectedTag?.tagName) {
            gameListRequest(_device, selectedProvider, 0, selectedCategory, [_selectedTag.tagName], gamesCount).then(
              (res: any) => {
                const newGames = res.games.map((game: any) => {
                  if (game.providerName === 'EGT Digital') {
                    return {
                      ...game,
                      gameKey: EGT_GAME_MAP[game.launchId],
                    };
                  } else {
                    return game;
                  }
                });
                _setTags(newGames);
                _setGamesTotalCount(res.totalCount);
              }
            );
          }
        }
      }
    }
  }, [selectedTag, tagsData, selectedProvider]);

  useEffect(() => {
    if (homeTag) {
      const _ = homeTag.hasHomeTag ? 'home' : 'allGames';
      (!selectedTag || selectedTag === 'home') && _setSelectedTag(_);
    }
  }, [homeTag, selectedTag]);

  return <div className="gametags_container">{gameCards}</div>;
};
export default GameTags;
