import { FC, useEffect } from 'react';
import { isDesktop, isMobileOnly } from 'react-device-detect';

const TawkChat: FC = () => {
  const tawkWidget = document.querySelector('.widget-visible') as HTMLElement;

  useEffect(() => {
    const setZIndex = (zIndex: string): void => {
      if (tawkWidget && isDesktop) {
        tawkWidget.style.setProperty('z-index', zIndex, 'important');
      }
    };
    if ((window as any)?.Tawk_API && Object.keys((window as any)?.Tawk_API).length) {
      if (isMobileOnly) {
        (window as any)?.Tawk_API?.hideWidget();
      } else {
        (window as any)?.Tawk_API?.showWidget();
        setZIndex('115');
      }
    }
    return () => {
      if ((window as any)?.Tawk_API && Object.keys((window as any)?.Tawk_API).length) {
        (window as any)?.Tawk_API?.showWidget();
        setZIndex('115');
      }
    };
  }, [tawkWidget]);

  return <></>;
};
export default TawkChat;
