import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from 'src/components/common/Loader';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { Typography } from 'src/components/common/Typography';
import { icons } from 'src/configs/icons';
import { reachTextParser } from 'src/helpers/utils';
import { useI18n } from 'src/hooks/use-i18n-translation';
import { CMSManager } from 'src/manager/CMSManager';
import { getRafTerms } from 'src/services/dataApi';
import _styles from '../../styles.module.scss';

type Props = {
  setShowTermsAndConditions: (show: boolean) => void;
};
const TermsAndConditions: FC<Props> = ({ setShowTermsAndConditions }) => {
  const { t } = useTranslation();
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const { locale } = useI18n();

  const closeTermsAndConditions = (): void => {
    setShowTermsAndConditions(false);
  };

  useEffect(() => {
    setLoading(true);
    getRafTerms(locale)
      .then((res: any) => {
        const _content = CMSManager.strapi.getRafTerms(res.data);
        setContent(_content);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [locale]);

  return (
    <div className={_styles.terms_and_condition_modal} style={loading ? { maxHeight: 'unset' } : {}}>
      <div className={_styles.header}>
        <Typography variant={'h4'} className={_styles.title}>
          {t('refer_a_friend.terms_and_conditions_title')}
        </Typography>
        <div className={_styles.close}>
          <SvgIcon src={icons.close} onClick={closeTermsAndConditions} />
        </div>
      </div>
      <div className={_styles.content}>
        <p>{loading ? <Loader /> : reachTextParser(content)}</p>
      </div>
    </div>
  );
};
export default TermsAndConditions;
