import HttpClient from './http';

const baseURL = 'https://payment.draftplaza.com';

export const getPlayerLimits = (type: 'deposit'): Promise<any> => {
  return HttpClient.get({ path: `/configs/getPlayerLimits?type=${type}`, baseURL }, true);
};

export const setPlayerLimit = (payload: {
  amount: number;
  period: 'month' | 'day' | 'week';
  type: 'deposit';
}): Promise<any> => {
  return HttpClient.post({ path: `/configs/setPlayerLimits`, baseURL }, payload, true);
};

export const updatePlayerLimit = (payload: {
  amount: number;
  period: 'month' | 'day' | 'week';
  type: 'deposit';
}): Promise<any> => {
  return HttpClient.post({ path: `/configs/updatePlayerLimits`, baseURL }, payload, true);
};
