/* eslint-disable max-len */
import cn from 'classnames';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Chat from 'src/components/base-components/Chat/Chat';
import Iframe from 'src/components/base-components/Iframe';
import MobileFixedMenu from 'src/components/base-components/OnlyMobileComponents/MobileFixedMenu';
import MobileMenu from 'src/components/base-components/OnlyMobileComponents/MobileMenu';
import MobileProviders from 'src/components/base-components/OnlyMobileComponents/MobileProviders';
import MobileSearch from 'src/components/base-components/OnlyMobileComponents/MobileSearch';
import SkinBuilder from 'src/components/base-components/SkinBuilder';
import Scrollbar from 'src/components/common/Scrollbar/ScrollBar';
import DesktopFooterConstructor from 'src/constructors/Footer/DesktopFooterConstructor';
import MobileFooterConstructor from 'src/constructors/Footer/MobileFooterConstructor';
import TabletFooterConstructor from 'src/constructors/Footer/TabletFooterConstructor';
import DesktopHeaderContructor from 'src/constructors/Header/DesktopHeaderConstructor';
import MobileHeaderContructor from 'src/constructors/Header/MobileHeaderConstructor';
import TabletHeaderConstructor from 'src/constructors/Header/TabletHeaderConstructor';
import DesktopSideBarConstructor from 'src/constructors/SideBarNavigation/DesktopSideBarConstructor';
import { detectDevice, envBoolean, getFromPath, setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { usePostMesssage } from 'src/hooks/use-postMessage';
import SectionLoaderPage from 'src/pages/SectionLoaderPage';
import { setSelectedCategory } from 'src/store/app/actions';
import { setFreeSpinGames, setGameBoxMissions } from 'src/store/integrations/actions';
import { RootState } from 'src/types/store-types';
import { IFRAMES } from 'src/utils/constants';
import './style.scss';

const MainLayout: FC<any> = (props) => {
  const device = detectDevice();
  const { pathname } = useLocation();

  const _freeSpinGamesReq = useConnectedAction(setFreeSpinGames.req);
  const _setGameBoxMission = useConnectedAction(setGameBoxMissions.req);
  const _setSelectedCategory = useConnectedAction(setSelectedCategory);

  const { showMenu, showSearch, showProviders } = useSelector((state: RootState) => state.mobile);
  const { user } = useSelector((state: RootState) => state.user);
  const { selectedCategory, sectionLoading, sideBarMenuIsOpen } = useSelector((state: RootState) => state.app);
  const { generalConfigs, navigation, headerTemplate } = useSelector((state: RootState) => state.configs);

  const [selectedDevice, setSelectedDevice] = useState<'desktop' | 'tablet' | 'mobile'>(device);

  const renderHelmet = (category: string): any => {
    switch (category) {
      case 'betting':
        return (
          <HelmetProvider>
            <Helmet>
              <title>Apostas esportivas</title>
              <meta
                name="description"
                content="Aposte nos melhores esportes online com odds ao vivo em futebol, basquete, UFC e mais. Tenha acesso às últimas odds de apostas e experimente a emoção das apostas em tempo real!"
              />
              <meta
                name="keywords"
                content="Aposte nos melhores esportes online com odds ao vivo em futebol, basquete, UFC e mais. Tenha acesso às últimas odds de apostas e experimente a emoção das apostas em tempo real!"
              />
            </Helmet>
          </HelmetProvider>
        );
      case 'liveSport':
        return (
          <HelmetProvider>
            <Helmet>
              <title>Apostas esportivas ao vivo – Futebol, basquete, hóquei, tênis, e-sports e muito mais!</title>
              <meta
                name="description"
                content="Aposte ao vivo em ligas de futebol, basquete, hóquei, tênis, sinuca e e-sports na Papi Games. Experimente probabilidades em tempo real e ação emocionante em todos os seus esportes favoritos. Inscreva-se agora para as melhores oportunidades de apostas ao vivo!"
              />
              <meta
                name="keywords"
                content="Aposte ao vivo em ligas de futebol, basquete, hóquei, tênis, sinuca e e-sports na Papi Games. Experimente probabilidades em tempo real e ação emocionante em todos os seus esportes favoritos. Inscreva-se agora para as melhores oportunidades de apostas ao vivo!"
              />
            </Helmet>
          </HelmetProvider>
        );
      case 'casinoTournament':
        return (
          <HelmetProvider>
            <Helmet>
              <title>
                Participe do PAPI SHOW & Game of Slots – Ganhe um prêmio total de $ 222.000 ou $ 22.000 diariamente!
              </title>
              <meta
                name="description"
                content="Participe do torneio diário PAPI SHOW para ter a chance de ganhar um prêmio de $222.000. Jogue o Game of Slots e ganhe até $22.000 diariamente. Entre agora para grandes vitórias!"
              />
              <meta
                name="keywords"
                content="Participe do torneio diário PAPI SHOW para ter a chance de ganhar um prêmio de $222.000. Jogue o Game of Slots e ganhe até $22.000 diariamente. Entre agora para grandes vitórias!"
              />
            </Helmet>
          </HelmetProvider>
        );
      default:
        return null;
    }
  };

  const isIframe = IFRAMES.includes(selectedCategory);
  const hasSkinBuilder =
    envBoolean(process.env.REACT_APP_SKIN_BUILDER_MODE as string) && !localStorage.getItem('dontShowSkinBuilderTool');

  useEffect(() => {
    window.onpopstate = function () {
      const newPathname = window.location.pathname;
      const newSelectedTab = getFromPath(newPathname, '/', 2);
      if (newSelectedTab) {
        _setSelectedCategory(newSelectedTab);
      } else {
        _setSelectedCategory('home');
      }
    };
  }, []);

  useEffect(() => {
    setSelectedDevice(device);
    isMobileOnly && setBodyOverflow(IFRAMES.includes(selectedCategory) ? 'unset' : 'set');
  }, [device, selectedCategory]);

  useEffect(() => {
    if (user) {
      _freeSpinGamesReq();
      _setGameBoxMission();
    }
  }, [user]);

  usePostMesssage((v: any) => setSelectedDevice(v.device), 'deviceType');

  // eslint-disable-next-line sonarjs/cognitive-complexity
  return useMemo(() => {
    switch (selectedDevice) {
      case 'mobile':
        return (
          <div
            id={isIframe ? selectedCategory + 'Id' : ''}
            className={cn(selectedCategory, headerTemplate, 'mobile', generalConfigs?.template, {
              ['vertical_wrapper']: generalConfigs?.isVertical,
            })}
          >
            {hasSkinBuilder && <SkinBuilder />}
            <MobileHeaderContructor />
            {process.env.REACT_APP_WEBSITENAME === 'PapiGames' && renderHelmet(selectedCategory)}
            {showMenu && <MobileMenu />}
            {showSearch && <MobileSearch />}
            {showProviders && <MobileProviders />}
            {envBoolean(process.env.REACT_APP_HAS_SECTION_LOADER as string) && !sectionLoading.includes(false) && (
              <SectionLoaderPage />
            )}
            {props.children}
            {navigation?.map(
              (category) => category?.preRenderIframe && <Iframe key={category.name} type={category.name} />
            )}
            <MobileFixedMenu />
            {!isIframe && <MobileFooterConstructor />}
          </div>
        );
      case 'tablet':
        return (
          <div
            className={cn(generalConfigs?.template, headerTemplate, 'layout_container', 'tablet', {
              ['layout_container__iframe']: isIframe,
              ['iframe_wrapper']: isIframe,
            })}
          >
            <Scrollbar>
              <div className={cn(selectedCategory, headerTemplate, 'tablet', generalConfigs?.template)}>
                <TabletHeaderConstructor />
                {process.env.REACT_APP_WEBSITENAME === 'PapiGames' && renderHelmet(selectedCategory)}
                {envBoolean(process.env.REACT_APP_HAS_SECTION_LOADER as string) && !sectionLoading.includes(false) && (
                  <SectionLoaderPage />
                )}
                {props.children}
                {navigation?.map(
                  (category) => category?.preRenderIframe && <Iframe key={category.name} type={category.name} />
                )}
                {!!generalConfigs?.chat && <Chat configs={generalConfigs?.chat} />}
                <TabletFooterConstructor />
              </div>
            </Scrollbar>
          </div>
        );
      default:
        if (envBoolean(process.env.REACT_APP_HAS_LOGIN_PAGE as string) && !user) {
          return null;
        } else {
          return (
            <div
              className={cn(generalConfigs?.template, headerTemplate, 'layout_container', 'desktop', {
                ['layout_container__iframe']: isIframe,
                ['sidebar_open']: sideBarMenuIsOpen,
                ['iframe_wrapper']: isIframe,
                ['vertical_wrapper']: generalConfigs?.isVertical,
              })}
            >
              {hasSkinBuilder && <SkinBuilder />}
              {generalConfigs?.hasSideBar && <DesktopSideBarConstructor />}
              <Scrollbar>
                <DesktopHeaderContructor />
                <div
                  className={cn(selectedCategory, generalConfigs?.template, 'desktop', 'page_content', {
                    ['sidebar_open']: sideBarMenuIsOpen,
                    ['iframe_wrapper']: isIframe,
                  })}
                  id={isIframe ? selectedCategory + 'Id' : ''}
                >
                  {process.env.REACT_APP_WEBSITENAME === 'PapiGames' && renderHelmet(selectedCategory)}
                  {envBoolean(process.env.REACT_APP_HAS_SECTION_LOADER as string) &&
                    !sectionLoading.includes(false) && <SectionLoaderPage />}
                  {props.children}
                  {navigation?.map(
                    (category) => category?.preRenderIframe && <Iframe key={category.name} type={category.name} />
                  )}
                  {!!generalConfigs?.chat && <Chat configs={generalConfigs.chat} />}
                </div>
                <DesktopFooterConstructor />
              </Scrollbar>
            </div>
          );
        }
    }
  }, [
    selectedDevice,
    showMenu,
    showSearch,
    showProviders,
    selectedCategory,
    pathname,
    sectionLoading,
    props.children,
    sideBarMenuIsOpen,
    generalConfigs,
    sideBarMenuIsOpen,
    isIframe,
    headerTemplate,
    user,
  ]);
};
export default MainLayout;
