import React, { FC } from 'react';
import { MediaManager } from 'src/manager/MediaManager';
import './styles.scss';

interface IDataType {
  img: string;
  link?: string;
}

type IProps = {
  settings: {
    priority: number;
    data: IDataType[];
  };
};

const RegulationIcons: FC<IProps> = ({ settings }) => {
  const { data } = settings;

  return (
    <div className="regulation_icons_container">
      {data?.map((el: IDataType) => {
        return (
          <div key={el.link} className="regulation_icons_item">
            {el.link ? (
              <a href={el.link} target="_blank" className="regulation_icons_link">
                <img
                  className="regulation_icons_img"
                  src={MediaManager.getSrcFromMediaAssets(el.img, '/footerIcons/')}
                  alt={el.img}
                />
              </a>
            ) : (
              <img
                className="regulation_icons_img"
                src={MediaManager.getSrcFromMediaAssets(el.img, '/footerIcons/')}
                alt={el.img}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default RegulationIcons;
