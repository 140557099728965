import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Dropdown from 'src/components/common/Dropdown/Dropdown';
import { Typography } from 'src/components/common/Typography';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useResetSignUpError } from 'src/hooks/useResetSignupErrors';
import { setError } from 'src/store/user/actions';
import { RootState } from 'src/types/store-types';
import * as Yup from 'yup';
import _styles from './styles.module.scss';

type Props = {
  onChange: (e: any) => void;
  name: string;
  required: boolean;
  label: boolean;
  data: any[];
};
const Employment_Select: FC<Props> = ({ onChange, name, required, label, data }) => {
  const { t }: Translation = useTranslation();

  const _setErrorReq = useConnectedAction(setError);

  const resetSignUpError = useResetSignUpError();

  const [employment, setEmployment] = useState<any>({});
  const { errors: signUpErrors } = useSelector((state: RootState) => state.user);

  const validationSchema = Yup.object().shape({
    employment: Yup.string().required(t('requiredField')),
  });

  const handleValidate = async (value: string): Promise<void> => {
    try {
      await validationSchema.validate({ employment: value }, { abortEarly: false });
      resetSignUpError('employment');
    } catch (error: any) {
      if (error.name === 'ValidationError') {
        _setErrorReq({ ...signUpErrors, employment: error.errors[0] });
      }
    }
  };

  const handleSelect = (option: any): void => {
    onChange({ target: { name, value: option.value } });
    setEmployment(option);
    handleValidate(option.value);
  };

  useEffect(() => {
    if (!Object.keys(employment).length) {
      const defaultSelected = data[0];
      if (defaultSelected) {
        handleSelect(defaultSelected);
      }
    }
  }, [employment, data]);

  return (
    <div className={_styles.dropdown_select__wrapper}>
      {label && (
        <Typography className={_styles.label} variant={'body5'}>
          {t(label)} {required && <span>*</span>}
        </Typography>
      )}
      <Dropdown
        list={data}
        selected={employment}
        handleSelect={handleSelect}
        className={_styles.dropdown_select}
        listClassName={_styles.dropdown_select__list}
        tabIndex={0}
      />
      <span className={_styles.error}>{signUpErrors.employment}</span>
    </div>
  );
};

export default Employment_Select;
