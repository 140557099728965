import { createReducer } from 'deox';
import produce from 'immer';
import moment from 'moment';
import { isMobileOnly } from 'react-device-detect';
import { convertMsToTime } from 'src/helpers/transformers';
import { setBodyOverflow } from 'src/helpers/utils';
import { closeDialog, closeErrorMessage, openDialog, openErrorMessage } from './actions';
import { IDialogManagerState } from './types';

const dialogManagerInitial: IDialogManagerState = {
  dialogs: {},
  error: '',
  errorParam: '',
  showErrorPopup: false,
  requestTrackId: '',
};
export const dialogManagerReducer = createReducer(dialogManagerInitial, (handleAction) => [
  handleAction(openDialog, (state, { payload }) =>
    produce(state, (draft) => {
      setBodyOverflow('unset');
      draft.dialogs[payload.dialogType] = payload.dialogProps;
    })
  ),
  handleAction(closeDialog, (state, { payload }) =>
    produce(state, (draft) => {
      // TO DO make it switch case after finding other cases
      if (isMobileOnly && payload.dialogType === 'changePasswordSuccess') {
        setBodyOverflow('unset');
      } else {
        setBodyOverflow('set');
      }
      delete draft.dialogs[payload.dialogType];
      if (payload.dialogProps) {
        draft.dialogs[payload.dialogType] = payload.dialogProps;
      }
    })
  ),
  // eslint-disable-next-line sonarjs/cognitive-complexity
  handleAction(openErrorMessage, (state, { payload }: any) =>
    produce(state, (draft) => {
      document.body.setAttribute('style', 'overflow:hidden');

      if (payload.errorMessage?.message?.key === 'invalid_cpf') {
        draft.errorParam = payload.errorMessage?.message?.params?.[1]
          ? Object.keys(payload.errorMessage?.message?.params?.[1])?.[0]
          : '';
        draft.error = payload.errorMessage?.message?.key;
      }

      if (payload.errorMessage?.message?.key === 'invalid_input') {
        draft.errorParam = payload.errorMessage?.message?.params?.[1]
          ? Object.keys(payload.errorMessage?.message?.params?.[1])?.[0]
          : '';
        draft.error = payload.errorMessage?.message?.key;
      }

      if (payload.errorMessage?.message?.key === 'invalid_national_id') {
        draft.errorParam = payload.errorMessage?.message?.params?.[1]
          ? Object.keys(payload.errorMessage?.message?.params?.[1])?.[0]
          : '';
        draft.error = payload.errorMessage?.message?.key;
      }

      if (!payload.errorMessage?.message?.params?.[0]) {
        draft.error = payload.errorMessage?.message?.key;
      }

      if (payload.errorMessage?.message?.key === 'withdraw_interval') {
        draft.error = payload.errorMessage?.message?.key;
        draft.errorParam = {
          interval: convertMsToTime(payload.errorMessage.datails?.intervalMs),
          time: moment(payload.errorMessage.datails?.availableAt).format('DD/MM | HH:mm'),
        };
      }

      if (['duplicate_document', 'document_not_found'].includes(payload.errorMessage?.message?.key)) {
        draft.errorParam =
          payload.errorMessage?.message?.params.length && Object.keys(payload.errorMessage?.message?.params)
            ? Object.keys(payload.errorMessage?.message?.params?.[1])?.[0]
            : '';
        draft.error = `${payload.errorMessage?.message?.key}${
          payload.errorMessage?.message?.params?.[0]
            ? '_' + (payload.errorMessage?.message?.params?.[0]).toLowerCase()
            : ''
        }`;
      }

      draft.showErrorPopup = true;
      draft.requestTrackId = payload?.errorMessage?.requestTrackId;
    })
  ),
  handleAction(closeErrorMessage, (state) =>
    produce(state, (draft) => {
      document.body.removeAttribute('style');
      draft.error = '';
      draft.showErrorPopup = false;
      draft.requestTrackId = '';
    })
  ),
]);
