import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

interface IDataType {
  title: string;
  link?: string;
}

type IProps = {
  settings: {
    priority: number;
    data: IDataType[];
  };
};

const ResponsibleGaming: FC<IProps> = ({ settings }) => {
  const { t } = useTranslation();
  const { data } = settings;

  return (
    <div className="responsible_gaming_container">
      {data.map((el: IDataType) => (
        <a key={el.title} href={el.link} target="_blank" className="responsible_gaming_item">
          {t(el.title)}
        </a>
      ))}
    </div>
  );
};

export default ResponsibleGaming;
