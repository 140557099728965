import { createAction } from 'deox';
import {
  APP_BALANCE_FAIL,
  APP_BALANCE_REQ,
  APP_BALANCE_SUCCESS,
  APP_SET_PASSWORD,
  APP_SET_PASSWORD_ERROR,
  APP_SET_PHONE_CODE,
  FIRST_DEPOSIT_TO_BONUS_FAILURE,
  FIRST_DEPOSIT_TO_BONUS_REQUEST,
  FIRST_DEPOSIT_TO_BONUS_SUCCESS,
  FORCE_LOGOUT,
  IAutoSignInReq,
  IFirstDepositToWallet,
  ISignInReq,
  LOGOUT,
  RESET_FORCE_LOGOUT,
  RESET_STORE,
  SET_CPF_USER,
  SET_REFERRAL_LINKS,
  TOGGLE_ACTIVATE_BUTTON,
  USER_AUTO_SIGN_IN_REQ,
  USER_SET_DNI_RESULT,
  USER_SET_ERROR,
  USER_SET_LOADING,
  USER_SET_UPDATE_USER,
  USER_SET_UPDATE_USER_LOADING,
  USER_SIGN_IN_FAIL,
  USER_SIGN_IN_LOAD,
  USER_SIGN_IN_REQ,
  USER_SIGN_IN_SUCCESS,
} from './types';

export const setLogout = createAction(LOGOUT, (res) => (params?) => res(params));

export const resetStore = createAction(RESET_STORE);

export const signIn = {
  req: createAction(USER_SIGN_IN_REQ, (res) => (params: ISignInReq) => res(params)),
  autoReq: createAction(USER_AUTO_SIGN_IN_REQ, (res) => (params: IAutoSignInReq) => res(params)),
  success: createAction(USER_SIGN_IN_SUCCESS, (res) => (params) => res(params)),
  fail: createAction(USER_SIGN_IN_FAIL),
  load: createAction(USER_SIGN_IN_LOAD, (res) => (params) => res(params)),
};

export const setBalance = {
  req: createAction(APP_BALANCE_REQ),
  success: createAction(APP_BALANCE_SUCCESS, (res) => (params) => res(params)),
  fail: createAction(APP_BALANCE_FAIL),
};

export const firstDepositToBonus = {
  req: createAction(FIRST_DEPOSIT_TO_BONUS_REQUEST, (res) => (params: IFirstDepositToWallet) => res(params)),
  success: createAction(FIRST_DEPOSIT_TO_BONUS_SUCCESS),
  fail: createAction(FIRST_DEPOSIT_TO_BONUS_FAILURE),
};

export const setCPF_User = createAction(SET_CPF_USER, (res) => (params: any) => res(params));

export const setError = createAction(USER_SET_ERROR, (res) => (params) => res(params));

export const setDniResult = createAction(USER_SET_DNI_RESULT, (res) => (params) => res(params));

export const setLoading = createAction(USER_SET_LOADING, (res) => (params) => res(params));

export const setUpdateUser = createAction(USER_SET_UPDATE_USER, (res) => (params) => res(params));

export const toggleActivateBtn = createAction(TOGGLE_ACTIVATE_BUTTON, (res) => (params) => res(params));

export const setPassword = createAction(APP_SET_PASSWORD, (res) => (params) => res(params));

export const setPhoneCode = createAction(APP_SET_PHONE_CODE, (res) => (params) => res(params));

export const setPasswordError = createAction(APP_SET_PASSWORD_ERROR, (res) => (params) => res(params));

export const setForceLogout = createAction(FORCE_LOGOUT, (res) => (params) => res(params));
export const resetForceLogout = createAction(RESET_FORCE_LOGOUT);

export const setReferralLink = createAction(SET_REFERRAL_LINKS, (res) => (params) => res(params));
export const userUpdateLoading = createAction(USER_SET_UPDATE_USER_LOADING, (res) => (params) => res(params));
