import { ChangeEvent, FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PopUpContainer from 'src/components/common/modal/PopUp';
import { makePath } from 'src/helpers/transformers';
import { getFromQueryParams, scrollToTop, setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useI18n } from 'src/hooks/use-i18n-translation';
import { useBannerActions } from 'src/hooks/useBannerActions';
import { CMSManager } from 'src/manager/CMSManager';
import { closeDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys } from 'src/store/dialog/types';
import { RootState } from 'src/types/store-types';
import './styles.scss';
import { IDataItem, IDataListProps, ITagList } from './types';

const FinalTourInfo: FC<IDataListProps> = ({ data }) => {
  const { search } = useLocation();
  const { locale } = useI18n();

  const { t } = useTranslation();
  const params = getFromQueryParams(search);

  const _closeDialog = useConnectedAction(closeDialog);

  const { selectedCategory } = useSelector((state: RootState) => state.app);
  const { categoriesData } = useSelector((state: RootState) => state.configs);
  const { tags } = useSelector((state: RootState) => state.games);

  const { providerAction, loginAction, registrAction, promotionAction, gameAction, categoryAction, externalUrlAction } =
    useBannerActions();

  const deviceType = isMobile ? 'mobile' : 'desktop';

  const hasPopUp = data?.some(
    (item: { attributes: { Device: { type: string } } }) => item?.attributes?.Device?.type === deviceType
  );

  const [checked, setChecked] = useState<boolean>(false);
  const [tagList, setTagList] = useState<ITagList[]>([]);
  const [filteredPopupData, setFilteredPopupData] = useState<IDataItem[]>([]);
  const [checkedItems, setCheckedItems] = useState<number[]>([]);

  const handleRedirect = (callAction: any, id: number): void => {
    scrollToTop();

    switch (callAction.Target) {
      case 'goToRegister':
        registrAction();
        break;
      case 'goToLogin':
        loginAction();
        break;
      case 'goToPromotion':
        promotionAction(makePath(callAction.TargetParam));
        break;
      case 'goToProvider':
        providerAction({ provider: callAction.TargetParam, tags: tags, tagList: tagList });
        break;
      case 'goToExternalUrl':
        externalUrlAction();
        break;
      case 'goToGame':
        gameAction(callAction.TargetParam);
        break;
      case 'goToCategory':
        categoryAction(callAction.TargetParam);
        break;
      case 'goToRace':
      case 'noAction':
        break;
      default:
        return;
    }
    closeModal(id);
  };

  const closeModal = (id: number): void => {
    checkedItems.push(id);
    const filteredData = filteredPopupData.filter((obj: { id: number }) => !checkedItems.includes(obj.id));
    setChecked(false);
    _closeDialog({ dialogType: EnumDialogsKeys.FINAL_TOUR_INFO, dialogProps: { data: filteredData } });
    setTimeout(() => {
      isMobile && params.affiliateId && setBodyOverflow('unset');
    });
  };

  const handleClickOnImg = (payload: any, id: number): void => {
    handleRedirect(payload, id);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>, popupId: number): void => {
    const isChecked = e.target.checked;
    setChecked(isChecked);
    const updatedCheckedItems = isChecked ? [...checkedItems, popupId] : checkedItems.filter((id) => id !== popupId);
    setCheckedItems(updatedCheckedItems);
    localStorage.setItem('dontShowPopupIds', JSON.stringify(updatedCheckedItems));
  };

  if (isMobile) {
    setBodyOverflow('unset');
  }

  const renderPopup = (
    id: number,
    imageUrl: string,
    callAction: any,
    showPopup: boolean,
    onClickRedirection: any
  ): JSX.Element | null => {
    return (
      <PopUpContainer
        wrapperId={id}
        isOpen
        handleClose={() => closeModal(id)}
        hasOverlay
        type="fullSize"
        key={id}
        className="final-tour-container"
      >
        <div className="final-tour">
          <img
            className="final-tour-container_img"
            src={(process.env.REACT_APP_FILE_STORAGE_ADDRESS as string) + imageUrl?.slice(1)}
            alt="final-tour"
            onClick={() => handleClickOnImg(onClickRedirection, id)}
          />
          {callAction && (
            <div className="final-tour__button-wrapper">
              <button onClick={() => handleRedirect(callAction, id)}>{t('finalPopupdetails')}</button>
            </div>
          )}
          {showPopup && (
            <div className="final-tour__dont-show-again-wrapper">
              <input
                id={`show-${id}`}
                type="checkbox"
                checked={checked}
                className="form-checkbox show_again"
                onChange={(e) => handleChange(e, id)}
              />
              <label htmlFor={`show-${id}`}>{t('dontShowAgain')}</label>
            </div>
          )}
        </div>
      </PopUpContainer>
    );
  };

  useEffect(() => {
    if (data) {
      const filteredData = CMSManager[process.env.REACT_APP_FILE_STORAGE_TYPE as string].getPublicPopups(
        data,
        deviceType,
        locale
      );

      setFilteredPopupData(filteredData);
    }
  }, [data, data.length]);

  useEffect(() => {
    const savedPopupIds = localStorage.getItem('dontShowPopupIds');

    if (savedPopupIds) {
      const parsedPopupIds = JSON.parse(savedPopupIds);

      const filteredData = filteredPopupData.filter((obj: { id: number }) => !parsedPopupIds.includes(obj.id));

      if (filteredData.length) {
        setFilteredPopupData(filteredData);
      }

      if (filteredData.length === 0 && filteredPopupData.length) {
        setFilteredPopupData([]);
      }
    }
  }, [filteredPopupData.length]);

  useEffect(() => {
    const savedPopupIds = JSON.parse(localStorage.getItem('dontShowPopupIds') || '[]');
    setCheckedItems(savedPopupIds);
  }, []);

  useEffect(() => {
    if (!hasPopUp) {
      _closeDialog({ dialogType: EnumDialogsKeys.FINAL_TOUR_INFO });
    }
  }, [hasPopUp]);

  useEffect(() => {
    categoriesData &&
      setTagList(
        categoriesData[selectedCategory]?.tags?.filter((data) => data.tagName === 'allGames')?.[0].tagGames || []
      );
  }, [categoriesData]);

  return (
    <>
      {filteredPopupData.map(
        (popup: { id: number; dontShowAgain: boolean; imageUrl: string; callAction: any; onClickRedirection: any }) => {
          const { id, callAction, dontShowAgain, imageUrl, onClickRedirection } = popup;
          return renderPopup(id, imageUrl, callAction, dontShowAgain, onClickRedirection);
        }
      )}
    </>
  );
};

export default FinalTourInfo;
