/**
 * Set icons config for icons in public/icons folder
 */
export const icons = {
  moreKebab: '/images/icons/svg/more-kebab.svg',
  clrt_icon: '/images/icons/svg/clrt_icon.svg',
  logout: '/images/icons/svg/logout.svg',
  sidebarArrow: '/images/icons/svg/sidebar_arrow.svg',
  close: '/images/icons/svg/close.svg',
  arrowDown: '/images/icons/svg/arrow_down.svg',
  arrowLeft: '/images/icons/svg/banner_left_arrow.svg',
  arrowRight: '/images/icons/svg/banner_right_arrow.svg',
  flag: '/images/icons/svg/flag.svg',
  view: '/images/icons/svg/view.svg',
  edit: '/images/icons/svg/edit.svg',
  hide: '/images/icons/svg/hide.svg',
  blog: '/images/icons/svg/blog.svg',
  promos: '/images/icons/svg/base-components/promos.svg',
  play: '/images/icons/svg/play-solid.svg',
  enFlag: '/images/icons/flags/english.png',
  trFlag: '/images/icons/flags/turkish.png',
  deFlag: '/images/icons/flags/germany.png',
  itFlag: '/images/icons/flags/italian.png',
  ptFlag: '/images/icons/flags/portugal.png',
  esFlag: '/images/icons/flags/spain.png',
  cnFlag: '/images/icons/flags/cn.png',
  krFlag: '/images/icons/flags/kr.jpg',
  chat: '/images/chat.png',
  done: '/images/icons/svg/done.svg',
  subtract: '/images/icons/svg/Subtract.svg',
  sandClock: '/images/icons/svg/sandClock.svg',
  controlPrev: '/images/icons/svg/control-prev.svg',
  helpCenter: '/images/icons/svg/helpCenter.svg',
  liveChat: '/images/icons/svg/liveChat.svg',
  backToTop: '/icons/svg/backToTop.svg',
  email: '/images/icons/svg/email.svg',
  cookiesManager: '/images/icons/svg/cookiesManager.svg',
  noResult: '/images/empty_box.svg',
  loading: '/images/loading.svg',
  errorIcon: '/images/error_icon.svg',
  userIcon: '/images/icons/svg/circled_avatar.svg',
  wallet: '/images/icons/svg/circled_wallet.svg',
  transfer: '/images/icons/svg/transfer.svg',
  swiper: '/images/icons/svg/swiper.svg',
  swiper_mobile: '/images/icons/svg/swiper_mobile.svg',
  refresh: '/images/refresh-bold.svg',
  mail: '/images/icons/svg/mail.svg',
  success: '/images/icons/svg/success_done.svg',
  gameBox: '/images/icons/header/gamebox.svg',
  remove: '/images/icons/svg/bin.svg',
  verifed: '/images/icons/svg/verifed.svg',
  unverifed: '/images/icons/svg/unverifed.svg',
};
